














// @ is an alias to /src
import { MutationPayload } from 'vuex';
import Modal from '@/components/modal.vue';
import store from '@/store';
import VotingWizard from '@/partials/VotingWizard.vue';
import { getVotingAnswers, getVotingPoles } from '@/api/votes';
import { IVote } from '@/models/vote';
import { exhibitorDetailModal, voteIcons } from './index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
    VotingWizard,
  },
  data() {
    return {
      modal: { ...exhibitorDetailModal, isCard: false },
      event: 0,
      exhibitor: 0,
      voteIcons: voteIcons,
      votes: [] as IVote[],
      answers: [],
    };
  },
  setup() {
    const { translations, contextVisitor } = useContext();
    return { translations, contextVisitor };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);

    if (this.contextVisitor.id) {
      // GET AVAILABLE VOTES

      getVotingPoles({
        event: this.event,
        type: this.contextVisitor.visitor_type === 'df' ? 'au' : undefined,
      }).then((response) => {
        this.votes = response.data.results;
      });
      getVotingAnswers({
        event: this.event,
        visitor: this.contextVisitor.id,
        exhibitor: this.exhibitor,
      }).then((response) => {
        this.answers = response.data.results as any;
      });
    }

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        console.log();
      }
      if (mutation.type === 'setContextVisitor' && mutation.payload.id) {
        if (this.contextVisitor.id) {
          getVotingPoles({
            event: this.event,
            type: this.contextVisitor.visitor_type === 'df' ? 'au' : undefined,
          }).then((response) => {
            this.votes = response.data.results;
          });
        }
      }
    });
  },
});
