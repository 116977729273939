import { IModal } from '@/components';
import router from '@/router';
import store from '@/store';
import { ITextarea } from '@/components/form/index';

export const exhibitorDetailModal: IModal = {
  isActive: true,
  isCard: true,
  close: () => {
    const event = parseInt(router.currentRoute.params.event, 10);
    const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);
    router.push(
      `/event/${event}/exhibitor/${exhibitor}`,
    );
  },
};

export const coverLetterConfig = () => {
  const translations = store.getters.getTranslations;
  const textarea: ITextarea = {
    id: 'cover-letter',
    name: 'cover_letter',
    label: translations.visitors.exhibitors.cover_letter,
    placeholder: translations.visitors.exhibitors.cover_letter_text,
    value: '',
  };

  return textarea;
};

export const modal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: () => {
    const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);
    const event = parseInt(router.currentRoute.params.event, 10);
    router.push(
      `/event/${event}/exhibitor/${exhibitor}`,
    );
  },
};

export const voteIcons = [
  {
    id: 1,
    icon: '',
  },
];
