

































































































import {
  defineComponent, PropType, reactive, ref, watch,
} from '@vue/composition-api';
import { addVotingAnswer, editVotingAnswer } from '@/api/votes';
import useContext from '@/composition/context';
import { IVote, IVoteAnswer, IVoteQuestion } from '@/models/vote';
import router from '@/router';
import store from '@/store';

// @ is an alias to /src
export default defineComponent({
  props: {
    votes: {
      type: Array as PropType<IVote[]>,
      required: true,
    },
    answers: {
      type: Array as PropType<IVoteAnswer[]>,
      required: false,
    },
  },
  setup(props) {
    const state = reactive({
      currentStep: 0,
      currentVote: 1,
      answers: [] as IVoteAnswer[],
      ready: false,
      loading: false,
    });
    const { contextVisitor, translations } = useContext();
    const answers = ref([] as IVoteAnswer[]);

    const event = parseInt(router.currentRoute.params.event, 10);
    const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);
    watch(
      () => props.answers,
      () => {
        const totalQuestions = props.votes
          .map((vote) => vote.questions.length)
          .reduce((acc, val) => acc + val);
        if (props.answers) {
          props.answers.forEach((answer) => {
            if (answer && answer.voting_poll !== undefined) {
              const currentVote = props.votes.find(
                (vote) => answer && vote.id === answer.voting_poll,
              );
              let questionIndex = 0;
              props.votes.forEach((vote) => vote.questions.forEach((question, i) => {
                if (question.id === answer.voting_question) {
                  questionIndex = i;
                }
              }));
              if (currentVote) {
                const voteIndex = props.votes.indexOf(currentVote);
                state.answers[getUniqueIndex(voteIndex, questionIndex)] = answer;
              }
            }
          });

          answers.value = props.answers;
          if (props.answers.length === totalQuestions) {
            state.currentVote = props.votes.length;
            state.currentStep = props.votes[props.votes.length - 1].questions.length;
            state.ready = true;
          }
        }
      },
    );

    const nextStep = (index: number) => {
      if (state.currentStep === props.votes[index].questions.length) {
        if (props.votes.length > state.currentVote) {
          state.answers.forEach((answer) => {
            const _answer = answers.value.find(
              (_answer) => _answer && answer && _answer.voting_question === answer.voting_question,
            );
            if (_answer) {
              answers.value[answers.value.indexOf(_answer)] = answer;
            } else {
              answers.value.push(answer);
            }
          });
          state.currentStep = 0;
          state.currentVote += 1;
        } else {
          state.ready = true;
        }
      } else {
        state.currentStep += 1;
      }
    };

    const previousStep = (index: number) => {
      if (
        state.currentStep === props.votes[index].questions.length &&
        props.votes.length < state.currentVote
      ) {
        state.currentStep = 1;
        state.currentVote -= 1;
      } else {
        state.currentStep -= 1;
      }
    };

    const getUniqueIndex = (voteIndex: number, questionIndex: number) => parseInt(`${voteIndex + 1}0${questionIndex + 1}`, 10);

    const addVote = (
      value: number,
      question: IVoteQuestion,
      questionIndex: number,
      voteIndex: number,
    ) => {
      if (state.answers[getUniqueIndex(voteIndex, questionIndex)]) {
        state.answers = state.answers.map((answer, i) => (i === getUniqueIndex(voteIndex, questionIndex) ? { ...answer, value } : answer));
      } else if (contextVisitor.value.id) {
        // ${voteIndex}00${questionIndex} === fastest way to generate an unique id based on vote index and question index
        const answers = [...state.answers];
        answers[getUniqueIndex(voteIndex, questionIndex)] = {
          exhibitor,
          visitor: contextVisitor.value.id,
          voting_question: question.id,
          value,
        };
        state.answers = answers;
      }
    };

    const submitVotes = async () => {
      state.loading = true;
      for (let i = 0; i <= state.answers.length; i++) {
        if (state.answers[i]) {
          if (
            props.answers &&
            props.answers.some((answer) => answer && answer.id && answer.id === state.answers[i].id)
          ) {
            await editVotingAnswer(state.answers[i]);
          } else {
            await addVotingAnswer(state.answers[i]);
          }
        }
      }
      store.commit('addPopup', {
        message: 'A sua votação foi submetida com sucesso!',
        type: 'success',
        autohide: true,
      });
      state.loading = false;
      router.push(`/event/${event}/exhibitor/${exhibitor}`);
    };

    return {
      state,
      nextStep,
      previousStep,
      addVote,
      submitVotes,
      translations,
      getUniqueIndex,
    };
  },
});
