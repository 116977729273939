import axios from 'axios';
import {
  IVotesParams, IVote, IVoteAnswer,
} from '@/models/vote';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const getVotingPoles = (params: IVotesParams) => {
  if (!params.page_size) {
    params.page_size = 100;
  }
  if (!params.page) {
    params.page = 1;
  }
  let requestUrl = `${url}/v2/voting-polls?page_size=${params.page_size}&page=${params.page}&ordering=order`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }
  if (params.type) {
    requestUrl += `&type=${params.type}`;
  }

  return axios.get(requestUrl, config());
};

export const getVotingResults = (params: IVotesParams) => {
  if (!params.page_size) {
    params.page_size = 100;
  }
  if (!params.page) {
    params.page = 1;
  }
  let requestUrl = `${url}/v2/voting-results?page_size=${params.page_size}&page=${params.page}`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }
  if (params.voting_poll_type) {
    requestUrl += `&voting_poll_type=${params.voting_poll_type}`;
  }
  if (params.voting_poll) {
    requestUrl += `&voting_poll=${params.voting_poll}`;
  }
  if (params.voting_question) {
    requestUrl += `&voting_question=${params.voting_question}`;
  }
  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }
  if (params.visitor) {
    requestUrl += `&visitor=${params.visitor}`;
  }

  return axios.get(requestUrl, config());
};

export const getVotingAnswers = (params: IVotesParams) => new Promise((resolve: Resolve<IData<IVoteAnswer>>) => {
  if (!params.page_size) {
    params.page_size = 100;
  }
  if (!params.page) {
    params.page = 1;
  }
  let requestUrl = `${url}/v2/voting-answers?page_size=${params.page_size}&page=${params.page}`;

  if (params.event) {
    requestUrl += `&voting_question__voting_poll__event=${params.event}`;
  }
  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }
  if (params.visitor) {
    requestUrl += `&visitor=${params.visitor}`;
  }
  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const addVotingAnswer = (answer: IVoteAnswer) => new Promise((resolve: Resolve<IVote>) => {
  const requestUrl = `${url}/v2/voting-answers`;

  axios
    .post(requestUrl, answer, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const editVotingAnswer = (answer: IVoteAnswer) => new Promise((resolve: Resolve<IData<IVote>>) => {
  const requestUrl = `${url}/v2/voting-answers/${answer.id}`;

  axios
    .patch(requestUrl, answer, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const deleteVotingAnswer = (answerId: number) => new Promise((resolve: Resolve<IData<IVote>>) => {
  const requestUrl = `${url}/v2/voting-answers/${answerId}`;

  axios
    .delete(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
